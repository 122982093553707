<template>
  <div class="bu-ju-3">
    <div class="top">
      <header-selete placeholder="需求名称搜索" @submit="resetList" ref="headerSelete"/>
    </div>
    <div class="body">
      <xia-la-load ref="xiaLa" @load="getLoad">
        <div class="xu-qiu"
             v-for="(vo,index) in dataList"
             :key="vo.id"
             @click="href(vo)"
        >
          <div class="xu-qiu-name van-ellipsis">
            {{ vo.title }}
          </div>
          <div class="miao-shu hui-se">
            {{ vo.miao_shu }}
          </div>
          <div class="biao-qian hui-se">
            <biao-qian :list="vo.biao_qian"/>
          </div>
          <div class="zhuan-tai hui-se">

          </div>
          <div class="edit">
            <div class="edit-create-time hui-se">
              发布时间：{{ vo.create_time }}
            </div>
            <div class="edit-btn">
              <a href="javascript:" v-if="vo.ywht_id" @click.stop="$refs.jinDu.show(vo.ywht_id)">查看详细进度</a>
              <a href="javascript:" v-else @click.stop="del(index)">删除需求</a>
            </div>
          </div>
        </div>
      </xia-la-load>
      <xiang-mu-jin-du ref="jinDu"/>
    </div>
    <div class="footer">
      <footer-nav/>
    </div>

  </div>
</template>

<script>
import FooterNav from "../open/footerNav";
import HeaderSelete from "../open/headerSelete";
import XiaLaLoad from "../../components/list/XiaLaLoad";
import http from "../../api/http";
import moment from "moment";
import XEUtils from "xe-utils";
import BiaoQian from "../open/biaoQian";
import {Dialog} from 'vant'
import XiangMuJinDu from "./public/xiangMuJinDu";

export default {
  name: 'WoDeXuQiu',
  components: {XiangMuJinDu, BiaoQian, XiaLaLoad, HeaderSelete, FooterNav},
  mixins: [],
  data() {
    return {
      where: {},
      dataList: []
    }
  },
  mounted() {
    window.faBuevent = () => {
      this.resetList()
    }
  },
  methods: {
    href(vo) {
      this.$router.push({name: 'XiangMuInfo', params: {xiang_mu_id: vo.id}})
    },
    resetList() {
      this.$refs.xiaLa.resetData()
    },
    getLoad(page) {
      this.where.page = page
      this.where.keyword = this.$refs.headerSelete.getKeyword()
      http.get('/gzhphp/user/woDeXuQiu', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item}) => {
          item = item.map(n => {
            n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD HH:mm')
            n.biao_qian = [n.type_name, n.address_name].filter(n => !XEUtils.isEmpty(n))
            return n
          })
          if (page === 1) this.dataList = item
          else this.dataList = [...this.dataList, ...item]
        })
      })
    },
    del(index) {
      Dialog.confirm({
        title: "提示",
        message: '确定删除'
      }).then(() => {
        http.post("/gzhphp/user_xiang_mu/delXuQiu", {id: this.dataList[index].id}, {load_success: true}).then(da => {
          if (da.code === 1) {
            XEUtils.remove(this.dataList, index)
          }

        })
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.xu-qiu {
  margin: 10px;
  background-color: @white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: @yin-ying;
  font-size: 16px;

  .hui-se {
    color: @gray-6;
  }

  .xu-qiu-name {
    font-weight: bold;
    margin-bottom: 6px;
  }

  .xu-qiu-name, .miao-shu, .biao-qian, .zhuan-tai {
    margin-bottom: 6px;
  }

  .edit {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;

    .edit-btn {
      a {
        margin-left: 5px;
      }
    }
  }
}
</style>
