<template>
  <div>
    <van-popup
        v-model:show="pageStatus"
        round
        position="bottom"
        :style="{ height: '300px' }"
    >
      <div class="page-content">
        <div class="page-content-item van-hairline--bottom" v-for="vo in infoList">
          <div class="lei-xing">{{ vo.join_min_type.name }}</div>
          <jin-du-xiang-xi :min="true" :is-zhe-die="false" :liu-cheng="vo.join_liu_cheng_all"/>
          <div class="cao-zuo">
            <a href="" @click.prevent="vo.min = !vo.min">
              <template v-if="vo.min">收起详细</template>
              <template v-else>查看详细进度</template>
            </a>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import http from "@/api/http";
import JinDuXiangXi from "@/views/user/public/jinDuXiangXi";

export default {
  name: 'xiangMuJinDu',
  components: {JinDuXiangXi},
  mixins: [],
  data() {
    return {
      pageStatus: false,
      infoList: []
    }
  },
  mounted() {
  },
  methods: {
    show(ywht_id) {
      http.get("/gzhphp/user_xiang_mu/getXiangMuJinDu", {params: {ywht_id}}).then(da => {
        if (da.code === 1) {
          this.infoList = da.data
          this.pageStatus = true
        }
      })
      //
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../../assets/basis_css/style/var";

.page-content {
  max-height: 270px;
  overflow-y: auto;
  .lei-xing {
    padding: 10px 10px 2px;
  }

  .cao-zuo {
    text-align: right;
    padding: 10px;
  }

  .page-content-item {
    background-color: @white;

  }

}
</style>
